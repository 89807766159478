import React from 'react';
import './index.scss';

const Header = () => {
  return (
    <div className="header">
      <header>Spacestagram</header>
    </div>
  );
};

export default Header;
